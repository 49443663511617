import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { animate } from '@lit-labs/motion';
import { classMap } from 'lit/directives/class-map.js';

@customElement('search-component')
export class Search extends LitElement {
    // Define scoped styles right with your component, in plain CSS
    static override styles = css`

    :host{
        position: relative;
    }

    input {
        background: none;
        margin: 0;
        padding: .4em;
        border: none;
        font: inherit;
        -webkit-appearance: none;
        color: white;
    }

    input::placeholder {
        color: rgba(255, 255, 255, .6);
    }

    input:focus {
        outline: none;
    }

    button {
        margin: 0;
        padding: .4em;
        min-height: 0;
        min-width: 0;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, .6);
        border: none;
        border-radius: 0;
        background: none;
        
    }

    button svg {
        width: 16px;
        height: 16px;
    }

    button svg * {
        fill: currentColor;
    }

    button:disabled {
        background: none;
    }


    .visuallyhidden {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap; /* added line */
    }

    form {
        display: flex;
        background: none;
        border: none;
        border: solid 2px rgba(255, 255, 255, .3);
        border-radius: 6px;
        overflow: hidden;
    }

    form.dropdown{
        border-radius: 6px 6px 0 0;
    }

    form:focus-within {
        outline: none;
        background: rgba(255, 255, 255, .8);
        color: black;
        border-color: white;
    }
    
    form:focus-within input {
        color: black;        
    }

    form:focus-within input::placeholder {
        color: #808080;        
    }

    form:focus-within button {
        color: var(--c-header-background);
        cursor: pointer;
    }

    form:focus-within button:hover {
        background: var(--c-brand);
        color: var(--c-brand-text);
    }

    form:focus-within button:disabled {
        background: none;
    }
    


    ul{
        list-style: none;
        margin:0;
        padding: 0;
        background: white;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        left: 0;
        top: 100%;
        height: 200px;
        width: 100%;
        color: black;
        border-radius: 0 0 6px 6px;
        box-shadow: rgba(0,0,0,.2) 2px 2px 2px;
    }

    ul li{
    }

    ul li a{
        text-decoration: none;
        color: inherit;
        display: block;
        padding: .5em;
        transition: background-color ease-in-out .2s;
    }

    ul li a:hover {
        background: rgba(0,0,0,.2);
    }

    ul li a:focus{
        background: var(--c-brand);
        color: var(--c-brand-text);
    }

    .empty{
        padding: 1em;
        background: white;
        position: absolute;
        left: 0;
        top: 100%;
        height: 200px;
        width: calc(100% - 2em);
        color: black;
        border-radius: 0 0 6px 6px;
        box-shadow: rgba(0,0,0,.2) 2px 2px 2px;
    }
  `;

    @property()
    action?: string = '';

    @state()
    q?: string = '';

    @state()
    focused: boolean = false;

    focusedItemIndex: number = -1;

    @state()
    results: SearchResult[] = [];


    duration = 200;

    constructor() {
        super();
    }

    // Render the UI as a function of component state
    override render() {
        return html`
            <form method="get" action=${this.action} @keyup=${this.keyup} class=${classMap({ dropdown: this.q != "" && this.focused })} @focusin=${this.focusin} @focusout=${this.focusout} >
                <label class="visuallyhidden" for="searchquery">Søg</label>
                <input type="search" name="q" placeholder="Søg" value="${this.q}" @input=${this.valueChanged} />
                <button type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" /></svg>
                    <span class="visuallyhidden">Søg</span>
                </button>

                ${this.resultList()}
            </form>
        `;
    }

    resultList() {
        let self = this;
        if (!this.focused) { return null; }
        if (this.results.length > 0) {
            return html`
                <ul class="results" ${animate({
                keyframeOptions: {
                    duration: this.duration,
                    fill: 'both',
                },
                in: [
                    { height: 0 },
                    { height: "200px" }
                ],
                out: [
                    { height: "200px" },
                    { height: 0 }
                ]
            })
                }>
                ${repeat(
                    this.results,
                    (i) => i,
                    (r) =>
                        html`<li>
                        <a @click=${self.linkClick} href=${r.url}>${r.header}</a> 
                        </li>`
                )}
            </ul>
            `
        }
        if (this.q?.length ?? 0 > 0) {
            return html`
                <div class="empty" ${animate({
                keyframeOptions: {
                    duration: this.duration,
                    fill: 'both',
                },
                in: [
                    { height: 0 },
                    { height: "200px" }
                ],
                out: [
                    { height: "200px" },
                    { height: 0 }
                ]
            })
                }>Ingen resultater</div>
            `;
        }
        return null;
    }


    private async valueChanged(e: InputEvent) {
        var n = (<HTMLInputElement>e.target).value;
        this.q = n;
        if (n.length == 0) {
            this.results = [];
            return;
        }
        await this.loadResuts();

    }


    private keyup(e: KeyboardEvent) {
        console.log(e);
        if (e.key == "ArrowDown") {
            //console.log("down");
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (this.focusedItemIndex == -1) {
                this.focusedItemIndex = 0;
            }
            else {
                this.focusedItemIndex = this.focusedItemIndex + 1;
            }
        }
        else if (e.key == "ArrowUp") {
            //console.log("up");
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (this.focusedItemIndex == -1) {
                return;
            }
            else {
                this.focusedItemIndex = this.focusedItemIndex - 1;
            }
        }
        else {
            //let input = this.renderRoot.querySelector("input")
            //input?.focus();
            //this.focusedItemIndex = -1;
            //if (//Number
            //    (e.keyCode >= 48 && e.keyCode <= 57)
            //    //uppecae
            //    || (e.keyCode >= 65 && e.keyCode <= 90)
            //    //lowercase
            //    || (e.keyCode >= 97 && e.keyCode <= 122)) {
            //    console.log(this.q);
            //    this.q += e.key;
            //    console.log(this.q);
            //    this.requestUpdate();
            //}


            return;
        }

        if (this.focusedItemIndex < 0) {
            this.renderRoot.querySelector("input")?.focus();
            this.focusedItemIndex = -1;
            return;
        }

        let items = this.renderRoot.querySelectorAll("ul li a");

        if (this.focusedItemIndex >= items.length) {
            this.focusedItemIndex = items.length - 1;
        }

        if (items && items.length > 0) {
            (<HTMLElement>items[this.focusedItemIndex]).focus();
        }
    }

    private focusin() {
        //console.log("focus");
        this.focused = true;
    }

    private focusout(e: FocusEvent) {
        //console.log("blur");
        //console.log((<HTMLElement>e.relatedTarget)?.closest("ul.results"));

        //This checks if the user clicked on a link or clicked outside to dismiss the popup
        if (e.relatedTarget == null || (e.relatedTarget && (<HTMLElement>e.relatedTarget).closest("ul.results") == null)) {
            //console.log("out");
            this.focused = false;
        }
    }

    private linkClick(e: MouseEvent) {
        e.stopPropagation();
        console.log(e);
    }

    private async loadResuts() {
        var r = await fetch("/api/search?q=" + this.q);
        if (r.ok) {
            let json = await r.json() as SearchResult[];
            this.results = json;
        }
    }
}

interface SearchResult {
    header: string;
    url: string;
    highlight: string;
}