import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { animate, fadeIn, fadeOut } from '@lit-labs/motion';

@customElement('homesearch-component')
export class Search extends LitElement {
    // Define scoped styles right with your component, in plain CSS
    static override styles = css`
    input{
        width: 100%;
        border: none;
        width: 100%;
        padding: 1rem;
        font: inherit;
        font-size: 1.5em;
        text-align: center;
        background: rgba(255,255,255,.4);
        transition: background-color ease-in-out .1s;
    }

    input:focus{
        outline: none;
        background: rgba(255,255,255,1);
    }

    ul{
        list-style: none;
        margin:0 0 1em 0;
        padding: 1em;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clamp(250px, 20vw, 350px), 1fr));
        gap: 1em;
        overflow: hidden;
    }

    ul li{
    }

    ul li a{
        text-decoration: none;
        color: inherit;
        display: block;
        background: white;
        padding: 1em;
        height: calc(100% - 2em);
    }
    ul li h2{
        margin: 0 0 .25em 0;
    }

    .empty{
        padding: 1em;
        font-weight: 300;
        font-size: 1.4em;
        text-align: center;
    }
  `;

    @state()
    q?: string = '';

    @state()
    results: SearchResult[] = [];


    duration = 200;
    private readonly storageKey = "lastHomeSearch";

    constructor() {
        super();
        let lastSearch = localStorage.getItem(this.storageKey);
        if (lastSearch) {
            this.q = lastSearch;
            this.loadResuts();
        }
    }

    // Render the UI as a function of component state
    override render() {
        return html`
            <input type="search" placeholder="Skriv for at søge" value="${this.q}" @input=${this.valueChanged} />
            ${this.resultList()}
        `;
    }

    resultList() {
        if (this.results.length > 0) {
            return html`
                <ul ${animate({
                keyframeOptions: {
                    duration: this.duration,
                    fill: 'both',
                },
                in: [
                    { maxHeight: 0 },
                    { maxHeight: "1000px" }
                ]
            })
                }>
                ${repeat(
                    this.results,
                    (i) => i,
                    (r, i) =>
                        html`<li ${animate({
                            keyframeOptions: {
                                duration: this.duration,
                                delay: (i * this.duration) / this.results.length,
                                fill: 'both',
                            },
                            in: fadeIn,
                            out: fadeOut
                        }

                        )}>
                        <a @click=${this.linkClicked} href=${r.url}>
                            <h2>${r.header}</h2>
                            ${unsafeHTML(r.highlight)}
                        </a> 
                    </li>`
                )}
            </ul>
            `
        }
        if (this.q?.length ?? 0 > 0) {
            return html`
                <div class="empty">Ingen resultater</div>
            `;
        }
        return null;
    }


    private async valueChanged(e: InputEvent) {
        var n = (<HTMLInputElement>e.target).value;
        this.q = n;
        if (n.length == 0) {
            this.results = [];
            localStorage.removeItem(this.storageKey);
            return;
        }
        await this.loadResuts();

    }

    private linkClicked() {
        if (typeof this.q === "string" && this.q.length > 0) {
            localStorage.setItem(this.storageKey, this.q);
        }
    }

    private async loadResuts() {
        var r = await fetch("/api/search?q=" + this.q);
        if (r.ok) {
            let json = await r.json() as SearchResult[];
            this.results = json;
        }
    }
}

interface SearchResult {
    header: string;
    url: string;
    highlight: string;
}